<template>
  <div class="page" v-if="show">
    <!-- <header class="header">拍品详情</header> -->
    <div class="main goods">
      <div class="thumb">
        <Swiper :autoPlay="false">
          <Slide
            v-for="(img, index) in goods.thumb_arr"
            :key="index"
            class="img"
          >
            <a :href="img.file_path"><img :src="img.file_path" /></a>
            <!-- <img class="logo" :src="shopInfo.logo" alt="" /> -->
          </Slide>
        </Swiper>
      </div>

      <div class="info">
        <div class="title">Lot {{ goods.order_by }} {{ goods.name }}</div>
        <div class="sub">
          微拍商家：<b>
            <router-link :to="'/' + shopInfo.id">{{
              shopInfo.shop_name
            }}</router-link>
          </b>
        </div>
        <div class="sub">
          拍卖专场：<b
            ><router-link
              :to="'/auction/' + shopInfo.id + '?id=' + auctionInfo.id"
              >{{ auctionInfo.title }}</router-link
            ></b
          >
        </div>
        <div class="sub">
          认证机构：<b>{{ goods.jigou }}</b>
        </div>
        <div class="sub">
          认证等级：<b>{{ goods.dengji }}</b>
        </div>
        <div class="sub">
          认证编号：<b>{{ goods.sn }}</b>
        </div>
        <div class="sub" v-if="auctionInfo.time">
          拍卖时间：<b
            >{{ auctionInfo.time.date }} {{ auctionInfo.time.time }}</b
          >
        </div>
      </div>

      <template v-if="goods.desc">
        <div class="content-title">拍品介绍</div>
        <div class="content" v-html="goods.desc"></div>
      </template>
    </div>

    <footer class="footer">
      <div class="icon" @click="$router.push('/' + shopInfo.id)">
        <img src="/home2.png" alt="" />
        <div>主页</div>
      </div>
      <div class="icon" @click="$router.push('/kefu/' + shopInfo.id)">
        <img src="/kefu2.png" alt="" />
        <div>客服</div>
      </div>
      <div class="icon" @click="$router.push('/fav/' + shopInfo.id)">
        <img src="/fav.png" alt="" />
        <div>收藏夹</div>
      </div>

      <div class="item">
        <div class="btn" @click="doFav" v-if="fav != 1">加入收藏</div>
        <div class="btn" style="background: orange" @click="doFav" v-else>
          已收藏
        </div>
      </div>
    </footer>
  </div>
</template>

<style lang='scss' scord>
.thumb {
  .img {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .logo {
      position: absolute;
      right: 20px;
      bottom: 20px;
      z-index: 100;
      max-width: 60px;
      max-height: 60px;
    }
  }
}
</style>

<script>
import { Swiper, Slide } from "vue-swiper-component";

export default {
  components: {
    Swiper,
    Slide,
  },
  data() {
    return {
      show: false,
      goods: {},
      auctionInfo: {},
      shopInfo: {},
      fav: -1,
    };
  },
  async mounted() {
    console.log(this.$route.name, 222);
    localStorage.setItem("backTo", this.$route.fullPath);

    const id = this.$route.params.goods_id;
    const r = await this.$axios.get("/goods/getInfo?goods_id=" + id);
    // console.log(r);

    this.show = true;

    if (r.status == 1) {
      this.goods = r.goods;
      this.shopInfo = r.shopInfo;
      this.auctionInfo = r.auctionInfo;
      this.fav = r.fav;
    }

    const that = this;
    // --------------- 获取signature ---------------
    const fullPath = this.$config.domain + this.$route.fullPath;

    this.signature = await this.$axios.post("/getSignature", {
      url: fullPath,
    });

    console.log(this.signature, 1111);

    wx.config({
      debug: false,
      appId: this.signature.appId,
      timestamp: Number(this.signature.timestamp),
      nonceStr: this.signature.nonceStr,
      signature: this.signature.signature,
      jsApiList: ["updateTimelineShareData", "updateAppMessageShareData"],
    });

    wx.ready(function () {
      //--------------- 分享 ---------------
      wx.updateAppMessageShareData(
        {
          title: that.goods.name + " -- 微拍预展平台", // 分享标题
          desc: that.shopInfo.shop_name, // 分享描述
          link: fullPath, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: that.goods.thumb, // 分享图标
        },
        function (res) {
          console.log(res, 11122);
          //这里是回调函数
        }
      );

      wx.updateTimelineShareData(
        {
          title: that.goods.name + " -- 微拍预展平台", // 分享标题
          link: fullPath, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: that.goods.thumb, // 分享图标
        },
        function (res) {
          //这里是回调函数
        }
      );
    });
    wx.error(function (res) {
      console.error(res, "---------------错误信息---------------");
    });
  },

  methods: {
    async doFav() {
      const r = await this.$axios.post("/goods/fav", {
        goods_id: this.goods.id,
        auction_id: this.goods.auction_id,
      });

      if (r.status == 1) {
        if (this.fav == 1) {
          this.fav = 0;
        } else {
          this.fav = 1;
        }
      }
    },
  },
};
</script>